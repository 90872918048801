import "../css/Pagination.css";

function Pagination({ length, page, pageSize, onPageChange }) {
  let lastPage = Math.ceil(length / pageSize);
  return (
    <>
      {length > pageSize && (
        <div className="pagination">
          <ul>
            {page > 1 && (
              <>
                <li
                  className="clickable"
                  onClick={() => onPageChange(parseInt(page) - 1)}
                >
                  {"<"}
                </li>
                <li className="clickable" onClick={() => onPageChange(1)}>
                  1
                </li>
              </>
            )}
            {page > 2 && (
              <>
                <li>...</li>
              </>
            )}
            <>
              <li>{page}</li>
            </>
            {page < lastPage - 1 && (
              <>
                <li>...</li>
              </>
            )}
            {page < length / pageSize && (
              <>
                <li
                  className="clickable"
                  onClick={() => onPageChange(lastPage)}
                >
                  {lastPage}
                </li>
                <li
                  className="clickable"
                  onClick={() => onPageChange(parseInt(page) + 1)}
                >
                  {">"}
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default Pagination;
