import {t} from '../../helpers'
import Carousel from "../Carousel";


export default function HomeCarouselInstagram({type, perView}) {
    return (
      <section className={`instagram-${type}-inner`}>
        <Carousel 
          controls={true}
          element={`glide-carousel-home-instagram-${type}`}
          options={{
            startAt: 0, 
            perView: perView, 
            autoplay: false, 
            gap: 15, 
            type: type, 
            peek: 50
          }}
        >
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167547902_2b.jpg&width=250&height=250"
              width="250"
              layout="responsive"
              height="250"
              alt="instagram"
            />
            <p>{t('home_ig_text_1')}</p>
          </div>
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167567650_3b.jpg&width=250&height=250"
              width="250"
              height="250"
              layout="responsive"
              alt="instagram"
            />
            <p>{t('home_ig_text_2')}</p>
          </div>
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167583779_4b.jpg&width=250&height=250"
              width="250"
              height="250"
              layout="responsive"
              alt="instagram"
            />
            <p>{t('home_ig_text_3')}</p>
          </div>
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167345252_1a.jpg"
              width="250"
              height="250"
              layout="responsive"
              alt="instagram"
            />
            <p>{t('home_ig_text_4')}</p>
          </div>
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167450065_3a.jpg&width=250&height=250"
              width="250"
              height="250"
              layout="responsive"
              alt="instagram"
            />
            <p>{t('home_ig_text_5')}</p>
          </div>
          <div className="home-carousel-instagram">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1583167471138_4a.jpg&width=250&height=250"
              width="250"
              height="250"
              layout="responsive"
              alt="instagram"
            />
            <p>{t('home_ig_text_6')}</p>
          </div>
        </Carousel>
      </section>
    )
  }
  