import { t } from "../../helpers";
import Carousel from "../Carousel";

export default function HomeCarouselQuotes({ type, perView }) {
  return (
    <section className={`quotes-${type}`}>
      <Carousel
        controls={true}
        element={`glide-carousel-home-quotes-${type}`}
        options={{
          startAt: 0,
          perView: perView,
          autoplay: false,
          gap: 0,
          type: type,
          peek: 0,
        }}
      >
        <div className="home-carousel-quote">
          <p>{t("home_press_text_1")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583244147590_glamour.png"
            width="150"
            height="37"
            alt="glamour"
          />
        </div>
        <div className="home-carousel-quote">
          <p>{t("home_press_text_2")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583166914972_logo-telva.png"
            width="150"
            height="40"
            alt="telva"
          />
        </div>
        <div className="home-carousel-quote">
          <p>{t("home_press_text_3")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583244109801_nw-press-bazaar.png"
            width="150"
            height="150"
            alt="bazaar"
          />
        </div>
        <div className="home-carousel-quote">
          <p>{t("home_press_text_4")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583244109801_nw-press-bazaar.png"
            width="150"
            height="150"
            alt="bazaar"
          />
        </div>
        <div className="home-carousel-quote">
          <p>{t("home_press_text_5")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583244087142_nw-press-vogue.png"
            width="150"
            height="150"
            alt="vogue"
          />
        </div>
        <div className="home-carousel-quote">
          <p>{t("home_press_text_6")}</p>
          <img
            style={{ width: "150px" }}
            src="https://global.cocunat.com/photos/1583167066113_369.medium.jpg"
            width="150"
            height="42"
            alt="mujerhoy"
          />
        </div>
      </Carousel>
    </section>
  );
}
