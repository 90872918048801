import RatingStars from "./RatingStars";
import { useState } from "react";
import { t } from "../helpers";
import CommentReply from "./CommentReply";
function Comment({
  title,
  comment,
  author,
  rating,
  lang,
  translation,
  replies = [],
  currentLanguage = null,
}) {
  const [translate, setTranslate] = useState(false);

  return (
    <div className="comment" style={{ padding: "15px 0px" }}>
      <p className="commentTitle">
        {translate === false ? title : translation.title}
      </p>
      <strong style={{ display: "block" }}>{author}</strong>
      <RatingStars rating={rating} />
      <p>{translate === false ? comment : translation.comment}</p>
      {replies.length > 0 &&
        replies.map((reply, i) => {
          if (reply.comment) {
            return (
              <CommentReply
                comment={
                  translate === false
                    ? reply.comment
                    : reply.translations && reply.translations[currentLanguage]
                    ? reply.translations[currentLanguage].comment
                    : null
                }
                author={reply.user_name}
                key={"reply-" + i}
              />
            );
          }
        })}

      {translation &&
        translation.comment &&
        currentLanguage &&
        lang != currentLanguage && (
          <p
            onClick={() => setTranslate(!translate)}
            className="translate-link"
          >
            {translate
              ? t("hide_comment_translation")
              : t("show_comment_translation")}
          </p>
        )}
    </div>
  );
}

export default Comment;
