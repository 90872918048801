import { getHeadersWithoutAuth } from "../helpers";
import axios from "axios";
import { t } from "../../helpers";
import { CCValidationError } from "./errors";

const BRAINTREE_API_BASE_URL = "https://cocunatapi.net/braintree";
export const CLIENT_ID =
  "AV5JsY82mt6Ep64ygxCBDSIe6TdFGN1-beVp_yKXI9onZg4lpgLXpXkUzW0BAb_e48SHZM_IDhSgmyjE";

export async function payment(nonce, cart) {
  try {
    await axios.post(
      `${BRAINTREE_API_BASE_URL}/payment`,
      {
        nonce: nonce,
        cart: cart,
      },
      getHeadersWithoutAuth()
    );
  } catch (err) {
    let isCCErr;
    try {
      isCCErr =
        err.response.data.chargePaymentMethod.transaction.status ===
        "PROCESSOR_DECLINED";
    } catch (_) {
      isCCErr = false;
    }

    if (err.response.status === 400 || isCCErr) {
      throw new CCValidationError({
        message: t("ccFailed", cart.language),
        code: 400,
      });
    } else {
      // rethrow the err
      throw err;
    }
  }
}

export async function paymentPaypal(nonce, cart) {
  try {
    await axios.post(
      `${BRAINTREE_API_BASE_URL}/payment/paypal`,
      {
        nonce: nonce,
        cart: cart,
      },
      getHeadersWithoutAuth()
    );
  } catch (err) {
    let isCCErr;

    if (err.response.status === 400 || isCCErr) {
      throw new CCValidationError({
        message: t("ccFailed", cart.language),
        code: 400,
      });
    } else {
      // rethrow the err
      throw err;
    }
  }
}

export async function getClientToken(currency) {
  return await axios.post(
    `${BRAINTREE_API_BASE_URL}/token`,
    {
      currency: currency,
    },
    getHeadersWithoutAuth()
  );
}
