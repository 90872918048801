import es from "./locale/es";
import { useContext } from "react";
import { CartContext } from "./context/cart";

export function urlToLocale(pathname) {
  const locale = pathname.substring(1, 6);
  const localeList = locale.split("-");

  if (localeList.length < 2) {
    return [];
  }

  if (localeList[0].length < 2 || localeList[1].length < 2) {
    return [];
  }

  return localeList;
}

export function getURLParams(search) {
  if (!search) {
    return {};
  }
  return search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

export function getJWT() {
  if (localStorage.getItem("jwt")) {
    return "JWT " + localStorage.getItem("jwt");
  }
  return null;
}

export function getHeaders() {
  return {
    headers: {
      Authorization: getJWT(),
      "Content-Type": "application/json",
    },
  };
}

export function overrideBodyClass(classList, newClass) {
  try {
    document.body.classList.remove(...classList);
    document.body.classList.add(newClass);
  } catch (err) {
    document.body.classList.add(newClass);
  }
}

export function buildPhotoUrl(
  image,
  height = 150,
  width = 150,
  fit = "contain",
  quality = 90
) {
  let domainS3 = "https://global.cocunat.com";
  let resizeDomain = "https://resizing.cocunat.com/r/";
  let imageUrl = `${domainS3}/${image}`;
  return `${resizeDomain}?quality=${quality}&fit=${fit}&width=${width}&height=${height}&image=${encodeURIComponent(
    imageUrl
  )}`;
}

function getTranslationSource() {
  if (window.cocunatTranslations) {
    return window.cocunatTranslations;
  }
  const lang = urlToLocale(window.location.pathname)[0];
  let translations;
  try {
    translations = require(`../src/locale/${lang}.json`);
  } catch (err) {
    translations = require(`../src/locale/en.json`);
  }
  window.cocunatTranslations = translations;
  return translations;
}

export const t = (key, params = {}) => {
  let trans = "";
  let translations = getTranslationSource();

  try {
    const nestedKeysList = key.split(".");
    trans = nestedKeysList.reduce((a, prop) => a[prop], translations);
  } catch (err) {
    trans = key;
  }

  try {
    for (key in params) {
      trans = trans.replace("{{" + key + "}}", params[key]);
    }
  } catch (err) {
    console.error(err);
  }

  return trans;
};

export const parseParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

/**
 * Basic email validation.
 * @param {*} email
 * @returns
 */
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

/**
 * Basic numeric  only validation.
 * @param {*} value
 * @returns
 */
export function validateNumericOnly(value) {
  var re = /^[0-9]+$/;
  return re.test(value);
}
