import { userLoggedIn } from "../account";
import { captureException } from "@sentry/browser";
import MD5 from "crypto-js/md5";

function getHashedEmail(email) {
  let md5_email = sessionStorage.getItem(email);
  if (md5_email == null) {
    md5_email = MD5(email.toLowerCase().replace(/\s/g, "")).toString();
    sessionStorage.setItem(email, md5_email);
  }
  return md5_email;
}

function set_resci_site() {
  window._rsq = window._rsq || [];
  let lang = localStorage.getItem("lang");
  if (lang) {
    lang = lang.toUpperCase();
    let site_ids = {
      ES: 1294,
      EN: 1291,
      IT: 1295,
      FR: 1293,
      DE: 1296,
    };
    let site_id = site_ids[lang];
    if (site_id === null) {
      site_id = site_ids.EN;
    }
    window._rsq.push(["_setSiteId", site_id]);
    window._rsq.push(["_enableOnsite"]);
  }
}

function init() {
  set_resci_site();
  let user = userLoggedIn();
  if (user) {
    window._rsq.push(["_setUserEmail", user.email]);
  }
}

export function trackPageView() {
  try {
    init();
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export function trackProductViewed(item_id) {
  try {
    init();
    window._rsq.push(["_addItem", { id: item_id }]);
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export function trackAddToCart(item_id, name, price_eur) {
  try {
    init();
    window._rsq.push([
      "_addItem",
      {
        id: item_id,
        name: name,
        price: price_eur,
      },
    ]);
    window._rsq.push(["_setAction", "shopping_cart"]);
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export function trackCheckoutSuccess(order_id, total_eur, orderlines_eur) {
  try {
    init();
    window._rsq.push(["_addOrder", { id: order_id, total: total_eur }]);

    orderlines_eur.forEach((item) => {
      window._rsq.push([
        "_addItem",
        {
          id: item.id,
          name: item.name,
          price: item.price,
        },
      ]);
    });
    window._rsq.push(["_setAction", "checkout_success"]);
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export function trackCartView(orderlines_eur) {
  try {
    init();
    orderlines_eur.forEach((item) => {
      window._rsq.push([
        "_addItem",
        {
          id: item.id,
          name: item.name,
          price: item.price,
        },
      ]);
    });
    window._rsq.push(["_setAction", "shopping_cart"]);
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export function trackProductSearch(search_term) {
  try {
    init();
    window._rsq.push(["_setAction", "search"]);
    window._rsq.push(["_setParams", { term: search_term }]);
    window._rsq.push(["_track"]);
  } catch (e) {
    captureException(e);
  }
}

export async function trackNewUserEmail(data, source) {
  set_resci_site();
  window._rsq.push(["_setUserEmail", data.email]);
  let md5_email = getHashedEmail(data.email);
  let payload = {
    record_id: md5_email,
    email: data.email,
    registration_source: source, //optional params only added if not undefined
    ...(data.first_name !== undefined &&
      data.last_name !== undefined && {
        full_name: data.first_name + " " + data.last_name,
      }),
    ...(data.address !== undefined && {
      address1: data.address,
    }),
    ...(data.city !== undefined && {
      city: data.city,
    }),
    ...(data.country !== undefined && {
      country: data.country,
    }),
    ...(data.zip_code !== undefined && {
      zip: data.zip_code,
    }),
    ...(data.phone !== undefined && {
      phone: data.phone,
    }),
  };
  console.log(payload);
  console.log(data);
  window._rsq.push(["_setUserProperties", payload]);
  window._rsq.push(["_setAction", "email_entered"]);
  window._rsq.push(["_track"]);

  // resci API_KEY needed for backend to work
  // axios.post(constants.API_BASE_URL + "resci_users/", payload);
}
