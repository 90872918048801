import Glide from "@glidejs/glide";
import React, { useState, useEffect } from "react";

const Carousel = ({
  element = "glide",
  options,
  children,
  controls = false,
}) => {
  const [slider] = useState(new Glide(`.${element}`, options));
  useEffect(() => {
    slider.mount();

    // subscribe to an event
    slider.on("run.before", (event) => {
      // ... do something cool here
    });

    // cleanup when unmounting component
    return () => slider.destroy();
  }, []);

  return (
    <div className={`${element} glide`}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {children.map((slide, index) => {
            return React.cloneElement(slide, {
              key: index,
              className: `${slide.props.className} glide__slide`,
            });
          })}
        </ul>
      </div>
      {controls && (
        <div className="glide-controls" data-glide-el="controls[nav]">
          {children.map((slide, index) => (
            <button
              data-glide-dir={`=${index}`}
              key={`button-${index}`}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
