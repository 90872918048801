import { t } from "../../helpers";

export default function HomeClaims({ lang }) {
  return (
    <section
      id="what-make-us-different"
      className="text-align-center"
      style={{
        backgroundColor: "#feecea",
        overflow: "hidden",
        padding: "30px 0px",
        marginTop: "35px",
        fontFamily: "Brown-Regular",
        fontSize: "17px",
      }}
    >
      <div className="container-wide">
        <p
          style={{
            fontFamily: "Brown-Bold",
            fontSize: "30px",
            maxWidth: "350px",
            margin: "15px auto 45px auto",
            lineHeight: "34px",
            textTransform: "none",
          }}
        >
          {t("what-make-us-different-title")}
        </p>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <img
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&width=75&height=75&image=https://global.cocunat.com/differents-new-1.png"
            height="75"
            width="75"
            loading="lazy"
            alt={t("what-make-us-different-1")}
          />
          <p>{t("what-make-us-different-1")}</p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <img
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&width=75&height=75&image=https://global.cocunat.com/differents-new-2.png"
            height="75"
            width="75"
            loading="lazy"
            alt={t("what-make-us-different-2")}
          />
          <p>{t("what-make-us-different-2")}</p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <img
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&width=75&height=75&image=https://global.cocunat.com/differents-new-3.png"
            height="75"
            width="75"
            loading="lazy"
            alt={t("what-make-us-different-3")}
          />
          <p>{t("what-make-us-different-3")}</p>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <img
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&width=75&height=75&image=https://global.cocunat.com/differents-new-4.png"
            height="75"
            width="75"
            loading="lazy"
            alt={t("what-make-us-different-4")}
          />
          <p>{t("what-make-us-different-4")}</p>
        </div>
      </div>
    </section>
  );
}
