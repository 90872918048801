import { t } from "../helpers";

const ProgressBar = ({
  amount,
  completed,
  type = "free_shipping",
  currency = {
    format: "left",
    symbol: "$",
  },
}) => {
  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "#fff3f6ff",
    borderRadius: 5.5,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "#ffc8c8ff",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  const textStyle = {
    textAlign: "center",
  };

  function formatPrice() {
    if (currency.format === "left") {
      return amount + currency.symbol;
    } else {
      return currency.symbol + amount;
    }
  }

  return (
    <div>
      <div style={textStyle}>
        <small>{t(`${type}_progress`, { amount: formatPrice() })}</small>
      </div>

      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
