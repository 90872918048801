import React from "react";
import { Redirect, Route, useParams } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("jwt");

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        const { lang, country } = props.match.params;
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect push to={`/${lang}-${country}/login`} />
        );
      }}
    />
  );
}

export default ProtectedRoute;
