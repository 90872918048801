import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { t } from "../../helpers";
import InputGroup from "../InputGroup";
import Button from "../Button";
import { addProductReview } from "../../logic/products";

const AddReview = React.memo(({ product = null, showForm = false }) => {
  let { slug, lang, country } = useParams();
  let [newCommentRating, setNewCommentRating] = useState(0);
  let [newCommentHover, setNewCommentHover] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  let [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(false);

  let defaultComment = {
    comment: "",
    email: "",
    lang: lang,
    product_id: product.id,
    rating: 0,
    title: "",
    user_name: "",
  };

  let [review, setReview] = useState(defaultComment);

  const isValidReview = (review) => {
    try {
      if (
        review.rating > 0 &&
        review.email.length > 1 &&
        review.user_name.length > 1 &&
        review.comment.length > 1
      ) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }

    return false;
  };

  function changeRating(e) {
    setNewCommentRating(e);
    let reviewCopy = { ...review };
    reviewCopy.rating = e;
    setReview(reviewCopy);
  }

  function changeReview(e) {
    let reviewCopy = { ...review };
    reviewCopy[e.target.name] = e.target.value;
    setReview(reviewCopy);
  }

  const saveReview = async () => {
    setIsLoading(true);
    const isValid = isValidReview(review);
    if (isValid) {
      setError(false);
      await addProductReview(product.id, review);
      setShowMessage(true);
      setReview(defaultComment);
      setNewCommentRating(0);
      setNewCommentHover(0);
      setIsLoading(false);
      setTimeout(function () {
        setShowMessage(false);
      }, 3000);
    } else {
      setError(t("all_fields_required"));

      setIsLoading(false);
    }
  };
  return (
    <section
      id="add-review-form"
      style={{ padding: "0px 15px", marginBottom: 20 }}
    >
      {error && <div className="alert alert-danger">⚠ {error}</div>}

      {showMessage && (
        <div
          style={{
            backgroundColor: "#EBF6F4",
            borderBottom: "2px solid #62C15A",
            padding: "0px 15px",
            zIndex: "1",
            width: "100%",
            position: "relative",
          }}
        >
          <span
            style={{
              color: "#767676",
              fontFamily: "Brown-Regular",
              position: "absolute",
              top: 0,
              right: 0,
              padding: "5px 10px",
              cursor: "pointer",
            }}
            onClick={() => setShowMessage(false)}
          >
            X
          </span>
          <p>
            <strong
              style={{
                color: "#62C15A",
                fontSize: "14px",
                padding: "20px 0px 0px 0px",
                display: "flex",
              }}
            >
              {t("review_thanks")}
            </strong>
          </p>
        </div>
      )}

      <div className="row">
        <div className="col-md-6">
          <InputGroup
            title={t("first_name")}
            name="user_name"
            value={review.user_name}
            onChange={changeReview}
          />
        </div>
        <div className="col-md-6">
          <InputGroup
            title={t("email")}
            name="email"
            value={review.email}
            onChange={changeReview}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="star-add-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={
                    index <= (newCommentHover || newCommentRating)
                      ? "on"
                      : "off"
                  }
                  onClick={() => changeRating(index)}
                  onMouseEnter={() => setNewCommentHover(index)}
                  onMouseLeave={() => setNewCommentHover(newCommentRating)}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-md-12">
          <InputGroup
            title="Title"
            name="title"
            value={review.title}
            onChange={changeReview}
          />
        </div>
        <div className="col-md-12">
          <textarea
            placeholder="Review"
            name="comment"
            value={review.comment}
            onChange={changeReview}
          ></textarea>
        </div>
        <div className="col-md-12">
          <Button
            size="big"
            width="wide"
            onClick={saveReview}
            loading={isLoading}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 15,
              fontSize: 13,
              backgroundColor: "black",
            }}
          >
            <span>{t("save")}</span>
          </Button>
        </div>
      </div>
    </section>
  );
});

export default AddReview;
