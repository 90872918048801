import React from "react";
import { buildPhotoUrl } from "../../helpers";

const ProductDescription = React.memo(({ product, productsPack }) => {
  return (
    <section id="description" className="container-small">
      <h3>{product.translation.catchy_title}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: product.translation.description }}
      />
      {productsPack && (
        <>
          {productsPack.map((inner, i) => (
            <div className="pd">
              <img
                src={buildPhotoUrl("photos/" + inner.image, 355, 285)}
                alt={inner.translation.name}
                height="55"
                width="55"
                loading="lazy"
              />
              <div className="pdContent">
                <span style={{ textDecoration: "underline" }}>
                  {inner.translation.name} ( {inner.attributes.metric_size} ml )
                </span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: inner.translation.summary,
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
});

export default ProductDescription;
