import { t } from "../../helpers";
import { Link, useParams } from "react-router-dom";

export default function HomeBanners() {
  let { lang, country } = useParams();

  return (
    <section
      id="category-banner-home"
      style={{ overflow: "hidden", margin: "0 -15px" }}
    >
      <Link
        to={`/${lang}-${country}/search/65`}
        className="category-banner col-md-3 col-sm-6"
      >
        <div className="content content-65">
          <div>
            <p>{t("category-home-65-title")}</p>
            <span>{t("category-home-65-desc")}</span>
          </div>
        </div>
      </Link>
      <Link
        to={`/${lang}-${country}/search/66`}
        className="category-banner col-md-3 col-sm-6"
      >
        <div className="content content-66">
          <div>
            <p>{t("category-home-66-title")}</p>
            <span>{t("category-home-66-desc")}</span>
          </div>
        </div>
      </Link>
      <Link
        to={`/${lang}-${country}/search/67`}
        className="category-banner col-md-3 col-sm-6"
      >
        <div className="content content-67">
          <div>
            <p>{t("category-home-67-title")}</p>
            <span>{t("category-home-67-desc")}</span>
          </div>
        </div>
      </Link>
      <Link
        to={`/${lang}-${country}/search/80`}
        className="category-banner col-md-3 col-sm-6"
      >
        <div className="content content-80">
          <div>
            <p>{t("category-home-80-title")}</p>
            <span>{t("category-home-80-desc")}</span>
          </div>
        </div>
      </Link>
    </section>
  );
}
