import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Comment from "../Comment";
import { t } from "../../helpers";
import RatingStars from "../RatingStars";
import Pagination from "../Pagination";
import InputGroup from "../InputGroup";
import AddReview from "./AddReview";

const ProductReviews = React.memo(
  ({
    comments,
    product = null,
    showViewAllButton = false,
    showForm = false,
  }) => {
    let { slug, lang, country } = useParams();
    const PAGE_SIZE = 10;
    let [page, setPage] = useState(1);
    let [firstComment, setFirstComment] = useState(0);
    let [lastComment, setLastComment] = useState(PAGE_SIZE);
    let [newCommentRating, setNewCommentRating] = useState(0);
    let [newCommentHover, setNewCommentHover] = useState(0);

    const fetchData = (_page) => {
      setPage(_page);
      let first = (parseInt(_page) - 1) * PAGE_SIZE;
      let last = first + PAGE_SIZE;
      setFirstComment(first);
      setLastComment(last);
      window.scrollTo(0, 0);
    };

    return (
      <section id="reviews">
        {product && product.comments_summary && (
          <div id="reviews-summary">
            <RatingStars rating={product.rating} size={32} />
            <p>
              {t("rating-based", {
                num: product.comments_summary
                  ? product.comments_summary.num
                  : 0,
              })}
            </p>
            <ul>
              <li>
                <span>5 {t("stars")}</span>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${product.comments_summary[5].percent}%`,
                    }}
                  ></div>
                </div>
                <span className="number">
                  {product.comments_summary[5].num}
                </span>
              </li>
              <li>
                <span>4 {t("stars")}</span>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${product.comments_summary[4].percent}%`,
                    }}
                  ></div>
                </div>
                <span className="number">
                  {product.comments_summary[4].num}
                </span>
              </li>
              <li>
                <span>3 {t("stars")}</span>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${product.comments_summary[3].percent}%`,
                    }}
                  ></div>
                </div>
                <span className="number">
                  {product.comments_summary[3].num}
                </span>
              </li>
              <li>
                <span>2 {t("stars")}</span>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${product.comments_summary[2].percent}%`,
                    }}
                  ></div>
                </div>
                <span className="number">
                  {product.comments_summary[2].num}
                </span>
              </li>
              <li>
                <span>1 {t("stars")}</span>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: `${product.comments_summary[1].percent}%`,
                    }}
                  ></div>
                </div>
                <span className="number">
                  {product.comments_summary[1].num}
                </span>
              </li>
            </ul>
          </div>
        )}
        <a name="comments"></a>
        <div className="comment-list" style={{ padding: "0px 15px" }}>
          {comments &&
            comments.slice(firstComment, lastComment).map((comment, i) => {
              if (comment.comment) {
                return (
                  <Comment
                    title={comment.title}
                    comment={comment.comment}
                    author={comment.user_name}
                    rating={comment.rating}
                    currentLanguage={lang}
                    lang={comment.lang}
                    translation={comment.translation}
                    replies={comment.replies}
                    key={"comment-" + i}
                  />
                );
              }
            })}
        </div>
        {product && <AddReview product={product} showForm={true} />}
        {!showViewAllButton && comments && comments.length > 0 && (
          <Pagination
            length={comments.length}
            page={page}
            pageSize={PAGE_SIZE}
            onPageChange={fetchData}
          />
        )}
        {showViewAllButton && comments && comments.length > 0 && (
          <Link
            to={`/${lang}-${country}/product/${slug}/reviews`}
            style={{
              border: "1px solid red",
              display: "block",
              height: "50px",
              textAlign: "center",
              lineHeight: "50px",
              textDecoration: "none",
              margin: "0px 15px",
            }}
          >
            {t("view-all-reviews")}
          </Link>
        )}
      </section>
    );
  }
);

export default ProductReviews;
