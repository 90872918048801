import constants from "../constants";
import React from "react";
import { useParams } from "react-router-dom";

function CountrySelector() {
  let { lang, country } = useParams();

  function reloadPage(event) {
    let locale = event.target.value;
    if (window.location.pathname.includes("/products")) {
      window.location.href = `/${locale}/`;
    } else {
      window.location.href = window.location.pathname.replace(
        `${lang}-${country}`,
        locale
      );
    }
  }

  return (
    <>
      <select
        defaultValue={`${lang}-${country.toUpperCase()}`}
        id="country"
        name="country"
        onChange={reloadPage}
      >
        {constants.COUNTRIES.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
          (country, i) => {
            return (
              <option key={i} value={`${country.lang}-${country.code}`}>
                {country.name}
              </option>
            );
          }
        )}
      </select>
    </>
  );
}

export default CountrySelector;
