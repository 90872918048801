import RadioGroup from "../RadioGroup";
import CONSTANTS from "../../constants";
import { t } from "../../helpers";
import { formatPrice } from "../../logic/helpers";
import { Link, useParams } from "react-router-dom";

export default function ProductGourmetSelector({
  product,
  show,
  selected,
  onChange,
  gourmetText,
}) {
  const prices = product.prices;
  const format = prices ? prices.format : "right";
  const symbol = prices ? prices.symbol : "";
  const price = prices ? prices.price : "";
  const discounted = prices ? prices.discounted : "";
  const gourmetProductDiscount = prices ? prices.gourmet_product_discount : "";
  const gourmetProductPrice = prices ? prices.gourmet_product_price : "";
  let { lang, country } = useParams();

  return (
    <>
      {show && (
        <div className="gourmet-selector">
          <RadioGroup
            options={[
              {
                value: false,
                title:
                  prices && discounted && price !== discounted
                    ? t("individual_purchase_with_discount", {
                        discounted: formatPrice(discounted, symbol, format),
                        original: formatPrice(price, symbol, format),
                      })
                    : t("individual_purchase", {
                        price: formatPrice(price, symbol, format),
                      }),
              },
              {
                value: CONSTANTS.SUBSCRIPTION_TYPES.GOURMET,
                title: t("purchase_with_gourmet", {
                  percentage: parseInt(gourmetProductDiscount) + "%",
                  discounted: formatPrice(gourmetProductPrice, symbol, format),
                  original: formatPrice(price, symbol, format),
                  link: `/${lang}-${country}/landing/cocunat-club`,
                }),
              },
            ]}
            defaultValue={selected}
            onChange={(option) => onChange(option.value)}
          />
        </div>
      )}
    </>
  );
}
