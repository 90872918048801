import { t } from "../helpers";
import { CartContext } from "../context/cart";
import { useContext } from "react";

export default function Promises({ size = "small", style = {} }) {
  const { region } = useContext(CartContext);
  return (
    <section id="promises" style={style}>
      <div>
        <img
          src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=37&height=25&image=https://global.cocunat.com/icons/8.png"
          width="37"
          height="25"
          alt="Free shipping"
        />
        {region && region.extra && region.extra.promises_text ? (
          <p>{region.extra.promises_text}</p>
        ) : (
          <p>
            {t("perks-free-shipping", {
              total: "",
            })}
          </p>
        )}
      </div>
      <div>
        <img
          src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=26&height=26&image=https://global.cocunat.com/icons/7.png"
          width="26"
          height="26"
          alt="Easy"
        />
        <p>{t("perks-devolutions")}</p>
      </div>
      <div>
        <img
          src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=26&height=26&image=https://global.cocunat.com/icons/6.png"
          width="26"
          height="26"
          alt="Results"
        />
        <p>{t("perks-results")}</p>
      </div>
    </section>
  );
}
