import React from "react";

const Packaging = React.memo(({ isMakeup = false }) => {
  return (
    <div
      id="packaging"
      style={
        isMakeup
          ? {
              flexGrow: "1",
              padding: "0px",
            }
          : {
              margin: "10px 0px 20px 0px",
              flexGrow: "1",
              padding: "0px 50px",
            }
      }
    >
      {isMakeup === true ? (
        <div className="row packaging-makeup">
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=50&height=50&image=https://global.cocunat.com/icons/ToxicFree.png"
              width="50"
              height="50"
              alt="Toxic Free"
            />
            <span>Toxic Free</span>
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=50&height=50&image=https://global.cocunat.com/icons/Natural.png"
              width="50"
              height="50"
              alt="Natural"
            />
            <span>Natural</span>
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=50&height=50&image=https://global.cocunat.com/icons/Sostenible.png"
              width="50"
              height="50"
              alt="Sustainable"
            />
            <span>Sustainable</span>
          </div>

          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=50&height=50&image=https://global.cocunat.com/icons/PETA.png"
              width="50"
              height="50"
              alt="Peta"
            />
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=300&height=300&image=https://global.cocunat.com/icons/CrueltyFree-2.png"
              width="100"
              height="100"
              alt="Cruelty Free"
              style={{ width: 125, marginTop: "-30px" }}
            />
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=50&height=50&image=https://global.cocunat.com/icons/ToxicFree.png"
              width="50"
              height="50"
              alt="Vegan Friendly"
            />
            <span>Vegan Friendly</span>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/9.png"
              width="36"
              height="36"
              alt="Cruelty Free"
            />
            <span>Cruelty Free</span>
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=17&image=https://global.cocunat.com/icons/10.png"
              width="36"
              height="17"
              alt="Approved by Peta"
            />
            <span>Approved by Peta</span>
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/11.png"
              width="36"
              height="36"
              alt="Vegan"
            />
            <span>Vegan</span>
          </div>
          <div className="packaging-item col-xs-6">
            <img
              src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/12.png"
              width="36"
              height="36"
              alt="No nasties"
            />
            <span>No nasties</span>
          </div>
        </div>
      )}
    </div>
  );
});

export default Packaging;
