const noop = () => {};

export function zarazEvent(eventName, eventData = null) {
  const zaraz = window.zaraz || noop;

  try {
    if (eventData) {
      zaraz.track(eventName, eventData);
    } else {
      zaraz.track(eventName);
    }
  } catch (err) {
    console.log(err);
  }
}

export function zarazEcommerce(eventName, eventData = null) {
  const zaraz = window.zaraz || noop;

  try {
    if (eventData) {
      zaraz.ecommerce(eventName, eventData);
    } else {
      zaraz.ecommerce(eventName);
    }
  } catch (err) {
    console.log(err);
  }
}
