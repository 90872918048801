import axios from "axios";

const AMAZON_PAY_API_BASE_URL = "https://cocunatapi.net/amazon-pay/";

export function createAmazonPayCheckoutSession(payload) {
  return axios.post(`${AMAZON_PAY_API_BASE_URL}checkout`, payload);
}

export function payAmazonPayCheckoutSession(sessionId, checkout) {
  return axios.post(
    `${AMAZON_PAY_API_BASE_URL}checkout/${sessionId}/pay`,
    checkout
  );
}

export function getAmazonPayCheckoutSession(sessionId) {
  return axios.get(`${AMAZON_PAY_API_BASE_URL}checkout/${sessionId}`);
}

export function updateAmazonPayCheckoutSession(sessionId, checkout) {
  return axios.patch(
    `${AMAZON_PAY_API_BASE_URL}checkout/${sessionId}`,
    checkout
  );
}
