import { t } from "../../helpers";
import React from "react";

const ProductIdealFor = React.memo(({ product }) => {
  return (
    <>
      {product.translation.ideal_for && (
        <section id="ideal-for">
          <img
            src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${product.translation.ideal_for_image}&width=450&height=700`}
            height="560"
            layout="responsive"
            width="360"
            alt="Ideal for"
            style={{ width: "100%", height: "auto" }}
          />
          <div className="container-small">
            <div className="ideal-for-text">
              <div className="text-box">
                <h4
                  style={{
                    margin: 0,
                    textAlign: "center",
                    fontSize: 24,
                    fontFamily: "Brown-Light",
                  }}
                >
                  {t("ideal-for-title")}
                </h4>
                <p
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: product.translation.ideal_for,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default ProductIdealFor;
