import { t } from "../helpers";

function PromoCodeInput(props) {
  return (
    <div className="promo-code-input">
      <input
        type="text"
        onChange={props.onChangeInput}
        value={props.value}
        autoComplete="on"
        name="promo_code"
        placeholder={t("promo_code_question", props.lang)}
        data-cy-promoinput=""
        className="input"
      />
      <button onClick={props.onSubmit}>{t("apply", props.lang)}</button>
    </div>
  );
}

export default PromoCodeInput;
