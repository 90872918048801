import RatingStars from "./RatingStars";
import { useState } from "react";
import { t } from "../helpers";

function CommentReply({ comment, author }) {
  const [translate, setTranslate] = useState(false);

  return (
    <div className="comment commentReply" style={{ padding: "15px 0px" }}>
      <strong style={{ display: "block" }}>{author}</strong>
      <p>{comment}</p>
    </div>
  );
}

export default CommentReply;
