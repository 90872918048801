import React, { useState } from "react";
import RadioGroup from "../RadioGroup";
import CONSTANTS from "../../constants";
import { t } from "../../helpers";
import { formatPrice } from "../../logic/helpers";

export default function ProductSubscriptionSelector({
  product,
  show,
  selected,
  onChange,
}) {
  const prices = product.prices;
  const format = prices ? prices.format : "right";
  const symbol = prices ? prices.symbol : "";
  const intervals =
    product.subscription && product.subscription.intervals
      ? product.subscription.intervals
      : [4, 8, 12, 16];
  const recommendedInterval =
    product.subscription && product.subscription.recommended
      ? product.subscription.recommended
      : 12;
  const price = prices ? prices.price : "";
  const discounted = prices ? prices.discounted : "";
  const subsProductDiscount = prices
    ? prices.regular_subscription_discount
    : "";
  const subsProductPrice = prices ? prices.regular_subscription_price : "";

  const [intervalSubscription, setIntervalSubscription] =
    useState(recommendedInterval);
  const [_selected, setSelected] = useState(selected);

  const onChangeInterval = (option) => {
    setIntervalSubscription(option);
    onChangeHandler({ selected: _selected, interval: option });
  };

  const onChangeSelect = (option) => {
    setSelected(option);
    onChangeHandler({ selected: option, interval: intervalSubscription });
  };

  const onChangeHandler = (data) => {
    onChange({
      selected: data["selected"],
      interval: parseInt(data["interval"]),
    });
  };

  return (
    <>
      {show && (
        <div id="subscription-select">
          <RadioGroup
            options={[
              {
                value: false,
                title:
                  prices && discounted && price !== discounted
                    ? t("individual_purchase_with_discount_no_club", {
                        discounted: formatPrice(discounted, symbol, format),
                        original: formatPrice(price, symbol, format),
                      })
                    : t("individual_purchase_no_club", {
                        price: formatPrice(price, symbol, format),
                      }),
              },
              {
                value: CONSTANTS.SUBSCRIPTION_TYPES.SIMPLE,
                title: t("purchase_with_simple", {
                  percentage: subsProductDiscount + "%",
                  discounted: formatPrice(subsProductPrice, symbol, format),
                  original: formatPrice(price, symbol, format),
                }),
              },
            ]}
            defaultValue={false}
            onChange={(option) => onChangeSelect(option.value)}
          />
          {_selected && intervals && recommendedInterval && (
            <div className="input-group">
              {intervals.length > 1 ? (
                <select
                  defaultValue={recommendedInterval}
                  onChange={(e) => onChangeInterval(e.target.value)}
                >
                  {intervals.map((interval, i) => {
                    return (
                      <option key={i} value={interval}>
                        {interval === recommendedInterval
                          ? t("subscriptionRecommended", {
                              value: interval,
                            })
                          : t("subscriptionOption", { value: interval })}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div className="input-group-text">
                  {t("subscriptionOption", { value: recommendedInterval })}
                </div>
              )}

              <p style={{ fontSize: 14, marginTop: 0 }}>{t("subscribeText")}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
