import { t } from "../helpers";
import React from "react";
import GoBackLink from "./GoBackLink";
import { Link, useParams } from "react-router-dom";

function EmptyCart(props) {
  let { lang, country } = useParams();
  return (
    <div
      className="container"
      style={{ textAlign: "center", marginBottom: "20px" }}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          padding: "30px 20px 20px 20px",
        }}
        dangerouslySetInnerHTML={{ __html: t("cart.empty_cart") }}
      />
      <div
        style={{
          margin: "20px 20px 20px 20px",
        }}
      >
        <Link
          to={`/${lang}-${country}/`}
          style={{
            backgroundColor: "rgb(255, 130, 114)",
            color: "rgb(255, 255, 255)",
            padding: "15px 60px",
            fontFamily: "Brown-Bold",
            fontSize: "1em",
          }}
        >
          {t("keep_shopping")}
        </Link>
      </div>

      <img
        height={300}
        src="https://global.cocunat.com/web/empty-cart.png"
        alt="thanks cart image"
        style={{
          margin: "20px 20px 20px 20px",
        }}
      />
    </div>
  );
}

export default EmptyCart;
