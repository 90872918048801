import { useState, useEffect } from "react";

function RadioGroup({
  options,
  type = "card",
  onChange,
  defaultValue = {},
  DescriptionComponent = null,
}) {
  let [selected, setSelected] = useState(false);
  function _onChange(option) {
    onChange(option);
    setSelected(option.value);
  }
  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);
  return (
    <>
      {options.map((option, i) => (
        <div key={i} className="radio-button" onClick={() => _onChange(option)}>
          <div className="radio-button__inner">
            {selected === option.value ||
            defaultValue.value === option.value ? (
              <div className="circle-o"></div>
            ) : (
              <div className="circle"></div>
            )}
            <span dangerouslySetInnerHTML={{ __html: option.title }} />
          </div>
          {DescriptionComponent && <DescriptionComponent option={option} />}
        </div>
      ))}
    </>
  );
}

export default RadioGroup;
