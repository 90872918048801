import { t } from "../../helpers";
import React from "react";

const CurlyLink = React.memo(({}) => {
  return (
    <>
      <section id="curly-container">
        <div className="container-small" style={{ position: "relative" }}>
          <div className="curly-text">
            <div className="text-box">
              <p
                style={{
                  margin: 0,
                  textAlign: "center",
                  fontSize: 17,
                  fontFamily: "Brown-Light",
                }}
              >
                {t("curly-text")}
              </p>

              <a
                href={`${t("curly-link")}`}
                className="btn btn-transparent"
                style={{
                  maxWidth: 300,
                  display: "block",
                  margin: "15px auto",
                  padding: "12px 0px",
                  color: "black",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <strong>{t("curly-button")}</strong>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default CurlyLink;
