import { t } from "../helpers";
function PromoCartBanner(props) {
  return (
    <div className="row promo-cart-banner">
      <div className="cart-image">
        <img
          alt=""
          src="https://global.cocunat.com/photos/cart-present-img.svg"
        />
      </div>
      <div
        style={{
          textAlign: "left",
          display: "inline-block",
          verticalAlign: "middle",
          width: "calc(100% - 70px)",
        }}
      >
        <p
          style={{ margin: "0px" }}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
    </div>
  );
}

export default PromoCartBanner;
